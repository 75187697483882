<template>
    <div v-loading="loading" element-loading-text="数据较多，拼命加载中..." class="projectSet-container common-padding">
        <div class="title-container"><div>项目设置</div></div>
        <breadcrumb :BreadcrumbData="BreadcrumbData"></breadcrumb>
        <div class="table-container">
            <table v-if="tableData && tableData.length">
                <tr class="table-title"><td>项目名称</td><td>所属客户</td><td>操作</td></tr>
                <tr v-for="(item, index) in tableData" :key="index">
                    <td>{{item.name}}</td>
                    <td>{{item.customerName}}</td>
                    <td>
                        <span class="color-blue">
                            <img v-show="index == 0" class="sortImg" src="~@/assets/images/up0.png"/>
                            <img @click="() => {projectSort(item, -1)} " v-show="index != 0" class="sortImg" src="~@/assets/images/up.png" />
                            <img v-show="tableData.length - (index + 1) == 0" class="sortImg" src="~@/assets/images/down0.png" />
                            <img @click="() => {projectSort(item, 1)}" v-show="tableData.length - (index + 1) != 0" class="sortImg"  src="~@/assets/images/down.png" />
                            <span class="blueBtn" @click="() => { editShow(item) }">编辑</span>
                        </span>
                        <!-- <span class="blueBtn">删除</span>  -->
                    </td>
                </tr>
            </table>
            <table v-else>
                <tr class="table-title"><td>项目名称</td><td>所属客户</td><td>操作</td></tr>
                <tr><td style="text-align: center" colspan="3">暂无数据</td></tr>
            </table>
        </div>
        <div class="projectSet-tip">注：首页项目只展示前三个，可根据调整项目顺序设置首页所展示的项目</div>
        <el-dialog v-loading="loading" element-loading-text="拼命加载中..." title="项目编辑" append-to-body width="520px" :visible.sync="editFormVisible" :before-close="handleClose">
            <el-form :model="editForm" :rules="rules" ref="editForm" label-width="100px">
                <el-form-item label="项目名称：" prop="name">
                    <el-input v-model="editForm.name" placeholder="请输入"></el-input>
                </el-form-item>
                <el-form-item label="所属客户：">
                    <span>{{editForm.customerName}}</span>
                    <!-- <el-input v-model="editForm.customerName" type="textarea" :autosize="{ minRows: 6, maxRows: 6 }" placeholder="请输入"></el-input> -->
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="editFormVisible = false" class="common-screen-btn">取 消</el-button>
                <el-button type="primary" @click="() => {sendForm('editForm')}" class="common-screen-btn">确 定</el-button>
            </div>
            </el-dialog>
    </div>
</template>
<script>
import Breadcrumb from "../../components/common/breadcrumb.vue";
import { projectList, projectSort, projectEdit } from "../../service/customer.js"
import { Config } from "../../utils/index.js"
export default {
    components: {
        Breadcrumb
    },
    data () {
        return {
            loading: false,
            editFormVisible: false,
            BreadcrumbData: [
                { title: "首页", isLink: true, url: "/index" },
                { title: "营销", isLink: false },
                { title: "设置", isLink: false },
                { title: "项目设置", isLink: false }
            ],
            editForm: {
                name: '',
                customerName: ''
            },
            rules: {
                name: [{ required: true, message: "请填写项目名称", trigger: "change"}],
            },
            tableData: [],
            companyId: this.$sto.get(Config.constants.userInfo).company_id, // 所属公司
        }
    },
    created() {
        this.projectList()
    },
    methods: {
        editShow(row) {
            this.editForm = {
                id: row.id,
                name: row.name,
                customerName: row.customerName
            }
            this.editFormVisible = true
        },
        sendForm(formName) {
            this.$refs[formName].validate(async (valid) => {
                if (valid) { // 校验成功
                    let editForm = {...this.editForm}
                    this.loading = true
                    let { code } = await projectEdit({name: editForm.name, id: editForm.id})
                    this.loading = false
                    if ( code == 200 ) {
                        this.$message.success('添加成功')
                        this.projectList()
                        this.editFormVisible = false;
                    }
                } else {
                    return false;
                }
            });
        },
        handleClose() {
            this.editFormVisible = false;
        },
        async projectSort(row, type) { // 上下移按钮
            this.loading = true
            let { code } = await projectSort({id: row.id, type: type})
            this.loading = false
            if( code == 200 ) {
                this.$message.success("排序成功")
                this.projectList()
            }
        },
        async projectList() {
            this.loading = true
            let { data } = await projectList({companyId: this.companyId})
            this.loading = false
            this.tableData = data || []
        }
    }
}
</script>
<style lang="less" scoped>
.projectSet-container {
    .color-blue {
        display: flex;
        align-items: center;
        .sortImg {
            margin-right: 8px;
        }
        .blueBtn {
            color: #1890ff !important;
            cursor: pointer;
            margin-left: 24px;
        }
    }
    .projectSet-tip {
        font-size: 14px;
        color: #101010;
        margin-top: 18px;
        text-align: left;
        padding-left: 32px;
    }
    .table-container {
        padding: 0 16px;
        table {
            width: 100%;
            .table-title {
                td {
                    font-weight: 700;
                }
            }
            td {
                border: 1px solid #dfdfdf;
                font-size: 14px;
                color: #333;
                padding: 20px 20px 20px 32px;
                text-align: left;
            }
        }
    }
}
.title-container {
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  padding: 0 0 16px;
  align-items: center;
  position: relative;
  &::after {
    content: " ";
    background: #e6e6e6;
    width: 120%;
    height: 1px;
    position: absolute;
    left: -10%;
    bottom: -1px;
    z-index: 999999;
  }
  div {
    font-size: 24px;
    color: #101010;
  }
}
.breadcrumb-container {
    margin-top: 24px;
}
</style>